import React from 'react';

import { type IconType } from '@ninox/ninox-components/lib/Icon';
import { Color, Size } from '@ninox/ninox-components/theme';
import { Container, Content, Title } from './LargeIconLayout.styles';
import { LargeIcon } from '../LargeIcon/LargeIcon';

type Properties = {
    icon: IconType;
    title: string;
    content?: string;
    children?: React.ReactNode;
};

export const LargeIconLayout = ({ children, icon, title, content }: Properties) => {
    return (
        <Container>
            <LargeIcon icon={icon} options={{ color: Color.neutral['1000'], filling: 1, size: Size.m }} />
            <Title>{title}</Title>
            {content && <Content>{content}</Content>}
            {children}
        </Container>
    );
};

export default LargeIconLayout;
