import React from 'react';

import { SvgIcon, type SvgIconProperties } from '@ninox/ninox-components/lib/Icon/shared';
import { Color, Size } from '@ninox/ninox-components/theme';

export const OverLimit = ({ color, ...properties }: SvgIconProperties) => {
    return (
        <SvgIcon intrinsicSize={56} stroke="none" {...properties}>
            <path
                d="M27.5 55.0428C12.5884 55.0428 0.457211 42.9116 0.457211 28C0.457211 13.0884 12.5884 0.957214 27.5 0.957214C42.4116 0.957214 54.5428 13.0884 54.5428 28C54.5428 42.9116 42.4116 55.0428 27.5 55.0428ZM27.5 2.9107C13.666 2.9107 2.4107 14.1661 2.4107 28C2.4107 41.834 13.666 53.0893 27.5 53.0893C41.334 53.0893 52.5893 41.834 52.5893 28C52.5893 14.1661 41.334 2.9107 27.5 2.9107Z"
                fill="currentColor"
            />
            <path
                d="M43.0367 34.4074H11.9633C10.4102 34.4074 9.14698 33.1442 9.14698 31.5912V24.4105C9.14698 22.8574 10.4102 21.5942 11.9633 21.5942H43.0384C44.5914 21.5942 45.8547 22.8574 45.8547 24.4105V31.5912C45.8547 33.1442 44.5898 34.4074 43.0367 34.4074ZM11.9633 23.5461C11.4879 23.5461 11.1005 23.9335 11.1005 24.4088V31.5895C11.1005 32.0649 11.4879 32.4523 11.9633 32.4523H43.0384C43.5137 32.4523 43.9012 32.0649 43.9012 31.5895V24.4088C43.9012 23.9335 43.5137 23.5461 43.0384 23.5461H11.9633Z"
                fill={color}
            />
        </SvgIcon>
    );
};

OverLimit.defaultProperties = {
    size: Size.xxl,
    color: Color.accent['700']
};
