import { toURLQueryString } from '@ninox/ninox-components/utils/toURLQueryString';

import type { ResetPasswordParams } from '../models/Accounts';
import type { ApiKey, ApiKeyErrorResponse } from '../models/ApiKey';
import type { ChangePasswordErrorResponse, ChangePasswordParams } from '../models/ChangePassword';
import type {
    ActiveDirectoryAuthenticationConfig,
    BasicAuthenticationConfig,
    Configuration,
    NetworkData,
    OpenIdAuthenticationConfig,
    OthersConfig,
    SamlAuthenticationConfig,
    SmtpEmailConfig,
    SslConfig
} from '../models/Configuration';
import type { CreateLicenseParams, CreateLicenseResponse } from '../models/CreateLicense';
import type { Customisation, UpdateCustomisationParams } from '../models/Customisation';
import type { DeployStatusResponse } from '../models/DeployStatus';
import type { DeployVersionParams, DeployVersionResponse } from '../models/DeployVersion';
import type { LicenseResponse } from '../models/License';
import type { MailHookErrorResponse, MailHookRequest, MailHookResponse } from '../models/MailHook';
import type { MaintenanceInfo, UpdateMaintenance, UpdateMaintenanceResponse } from '../models/Maintenance';
import type { Database } from '../models/ProcessMonitor/Database';
import type { Events } from '../models/ProcessMonitor/Events';
import type { TeamMetric } from '../models/ProcessMonitor/TeamMetric';
import type { TransactionResponse } from '../models/ProcessMonitor/Transaction';
import type { SqlConnection, SqlConnectionParams } from '../models/SqlConnection';
import type { UsageData } from '../models/Usage';
import type { CreateUserParams, UpdateUserParams, UpdateUserResponse, UserPrivate } from '../models/User.private';
import type { VersionResponse } from '../models/Version';
import type { UpdateWorkspaceParams, Workspace } from '../models/Workspace';
import { request } from '../utils/request';
import { methods } from './api.types';

const API_PATH = process.env.API_PATH || '';

/**
 * PrivateClient connects to the private cloud's api
 * @param {string}  url private cloud url
 */

export const PrivateClient = {
    getSessionInfo() {
        return request<undefined, UserPrivate>(`${API_PATH}/ums/admin`);
    },
    checkServer() {
        return request<undefined, { message: 'OK' | string }>(`${API_PATH}/ums/check`);
    },
    // Account
    verifyEmail(token: string) {
        const queryString = toURLQueryString({ token });

        return request<undefined, { success: boolean }>(`${API_PATH}/ums/credentials/verify-email?${queryString}`);
    },
    verifyResetPasswordToken(token: string) {
        const queryString = toURLQueryString({ token });

        return request<undefined, { success: boolean }>(
            `${API_PATH}/ums/credentials/verify-reset-password-token?${queryString}`
        );
    },
    resetPassword(data: ResetPasswordParams) {
        return request<ResetPasswordParams, ChangePasswordErrorResponse | Record<string, never>>(
            `${API_PATH}/ums/credentials/resetPassword`,
            methods.POST,
            data
        );
    },
    acceptInvitation(invitation: string) {
        return request<undefined, unknown>(`${API_PATH}/ums/credentials/en/accept-invitation?invitation=${invitation}`);
    },

    // Workspaces
    getWorkspaces() {
        return request<undefined, Workspace[]>(`${API_PATH}/ums/admin/teams`);
    },
    getWorkspace(id: string) {
        return request<undefined, Workspace>(`${API_PATH}/ums/admin/team/${id}`);
    },
    updateWorkspace(id: string, data: UpdateWorkspaceParams) {
        return request<UpdateWorkspaceParams, Workspace>(`${API_PATH}/ums/admin/team/${id}`, methods.POST, data);
    },
    deleteWorkspace(id: string) {
        return request(`${API_PATH}/ums/admin/team/${id}`, methods.DELETE);
    },
    getWorkspaceMigrationProgress(workspaceId: string) {
        return request<undefined, { total: number; completed: number }>(
            `${API_PATH}/ums/admin/team/${workspaceId}/migration-progress`
        );
    },

    // Users
    getUsers() {
        return request<undefined, UserPrivate[]>(`${API_PATH}/ums/admin/users`);
    },
    getUser(userId: string) {
        return request<undefined, UserPrivate>(`${API_PATH}/ums/admin/user/${userId}`);
    },
    createUser(data: CreateUserParams) {
        return request<CreateUserParams, UserPrivate>(`${API_PATH}/ums/admin/user`, methods.POST, data);
    },
    updateUser(userId: string, data: UpdateUserParams) {
        return request<UpdateUserParams, UpdateUserResponse>(
            `${API_PATH}/ums/admin/user/${userId}`,
            methods.POST,
            data
        );
    },
    deleteUser(userId: string) {
        return request<undefined, string>(`${API_PATH}/ums/admin/user/${userId}`, methods.DELETE);
    },
    changeUserPassword(data: ChangePasswordParams) {
        return request<ChangePasswordParams, ChangePasswordErrorResponse | Record<string, never>>(
            `${API_PATH}/ums/admin/security/password`,
            methods.PUT,
            data
        );
    },

    // Integrations
    getApiKeys() {
        return request<undefined, ApiKey[]>(`${API_PATH}/ums/admin/apikey`);
    },
    createApiKey() {
        return request(`${API_PATH}/ums/admin/apikey`, methods.POST);
    },
    deleteApiKey(token: string) {
        return request<undefined, ApiKey[] | ApiKeyErrorResponse>(
            `${API_PATH}/ums/admin/apikey/${token}`,
            methods.DELETE
        );
    },
    updateApiKey(data: ApiKey) {
        return request(`${API_PATH}/ums/admin/apikey/${data.token}`, methods.PUT, data);
    },
    getRoles() {
        return request<undefined, { caption: string }[]>(`${API_PATH}/ums/admin/roles`);
    },

    // Usage Overview
    getUsageData(userId: string) {
        return request<undefined, UsageData>(`${API_PATH}/ums/stats/users/${userId}/summary`);
    },

    updatePrivateLicenseFile(data: FormData) {
        return request<FormData, undefined>(`${API_PATH}/ums/admin/upload-license`, methods.POST, data);
    },
    updatePrivateLicense(data: CreateLicenseParams) {
        return request<CreateLicenseParams, CreateLicenseResponse>(`${API_PATH}/ums/admin/license`, methods.POST, data);
    },

    //Process Monitor
    getTeamMetrics() {
        return request<undefined, TeamMetric[]>(`${API_PATH}/ums/admin/team-metrics`);
    },
    getTransactions(workspaceId: string) {
        return request<undefined, TransactionResponse>(`${API_PATH}/${workspaceId}/root/processmonitor/transactions`);
    },
    getEvents(workspaceId: string, requestId: string) {
        return request<undefined, Events>(
            `${API_PATH}/${workspaceId}/root/processmonitor/transactions/${requestId}/events-script`
        );
    },
    restartWorkspace(workspaceId: string) {
        return request(`${API_PATH}/${workspaceId}/root/die/${workspaceId}`);
    },
    getDatabases(workspaceId: string) {
        return request<undefined, Database[]>(`${API_PATH}/${workspaceId}/root/databases/list`);
    },

    // Databases
    getSqlConnections() {
        return request<undefined, SqlConnection[]>(`${API_PATH}/ums/admin/connections`);
    },
    getSqlConnection(id: string) {
        return request<SqlConnectionParams, SqlConnection>(`${API_PATH}/ums/admin/connection/${id}`);
    },
    createSqlConnection(data: SqlConnectionParams) {
        return request<SqlConnectionParams, SqlConnection>(`${API_PATH}/ums/admin/connection`, methods.POST, data);
    },
    updateSqlConnection(id: string, data: SqlConnectionParams) {
        return request<SqlConnectionParams, SqlConnection>(
            `${API_PATH}/ums/admin/connection/${id}`,
            methods.PATCH,
            data
        );
    },
    deleteSqlConnection(id: string) {
        return request(`${API_PATH}/ums/admin/connection/${id}`, methods.DELETE);
    },
    testSqlConnection(id: string) {
        return request(`${API_PATH}/ums/admin/connection/test/${id}`);
    },

    // Configuration
    getConfiguration() {
        return request<undefined, Configuration>(`${API_PATH}/ums/admin/config`);
    },
    getBasicAuthentication() {
        return request<undefined, BasicAuthenticationConfig>(`${API_PATH}/ums/admin/config/auth/basic`);
    },
    updateBasicAuthentication(data: BasicAuthenticationConfig) {
        return request(`${API_PATH}/ums/admin/config/auth/basic`, methods.PUT, data);
    },
    getSAMLAuthentication() {
        return request<undefined, SamlAuthenticationConfig>(`${API_PATH}/ums/admin/config/auth/sso-saml`);
    },
    updateSAMLAuthentication(data: FormData) {
        return request(`${API_PATH}/ums/admin/config/auth/sso-saml`, methods.PUT, data);
    },
    getOpenIDAuthentication() {
        return request<undefined, OpenIdAuthenticationConfig>(`${API_PATH}/ums/admin/config/auth/sso-oidc`);
    },
    updateOpenIDAuthentication(data: OpenIdAuthenticationConfig) {
        return request(`${API_PATH}/ums/admin/config/auth/sso-oidc`, methods.PUT, data);
    },
    getActiveDirectoryAuthentication() {
        return request<undefined, ActiveDirectoryAuthenticationConfig>(`${API_PATH}/ums/admin/config/auth/sso-adds`);
    },
    updateActiveDirectoryAuthentication(data: ActiveDirectoryAuthenticationConfig) {
        return request(`${API_PATH}/ums/admin/config/auth/sso-adds`, methods.PUT, data);
    },
    updateConfigSmtpEmail(data: SmtpEmailConfig) {
        return request(`${API_PATH}/ums/admin/config/smtp`, methods.PUT, data);
    },
    updateConfigOthers(data: OthersConfig) {
        return request(`${API_PATH}/ums/admin/config/others`, methods.PUT, data);
    },
    getSSL() {
        return request<undefined, SslConfig>(`${API_PATH}/ums/admin/config/ssl`);
    },
    updateSSL(data: SslConfig) {
        return request(`${API_PATH}/ums/admin/config/ssl`, methods.PUT, data);
    },
    getNetworkData() {
        return request<undefined, NetworkData>(`${API_PATH}/ums/admin/config/network-data`);
    },
    updateNetworkData(data: NetworkData) {
        return request(`${API_PATH}/ums/admin/config/network-data`, methods.PUT, data);
    },
    resetAuthentication() {
        return request(`${API_PATH}/ums/admin/config/auth/reset`, methods.POST);
    },
    resetSmtpEmail() {
        return request(`${API_PATH}/ums/admin/config/smtp/reset`, methods.POST);
    },

    // Customization
    getCustomization() {
        return request<undefined, Customisation>(`${API_PATH}/ums/admin/customization`);
    },
    updateCustomization(data: UpdateCustomisationParams) {
        return request(`${API_PATH}/ums/admin/customization`, methods.POST, data);
    },

    // License
    getLicense() {
        return request<undefined, LicenseResponse>(`${API_PATH}/ums/admin/license`);
    },

    // Maintenance
    getMaintenanceInfo() {
        return request<undefined, MaintenanceInfo>(`${API_PATH}/ums/admin/maintenance`);
    },
    updateMaintenance(data: UpdateMaintenance) {
        return request<UpdateMaintenance, UpdateMaintenanceResponse>(
            `${API_PATH}/ums/admin/maintenance`,
            methods.POST,
            data
        );
    },
    restartServer() {
        return request(`${API_PATH}/ums/admin/maintenance/restart`);
    },

    // Deploy version
    deployVersion(version: string) {
        return request<DeployVersionParams, DeployVersionResponse>(`${API_PATH}/ums/deploy`, methods.POST, {
            version
        });
    },
    getDeploymentStatus() {
        return request<undefined, DeployStatusResponse>(`${API_PATH}/ums/deployment/status`);
    },
    getVersions() {
        return request<undefined, VersionResponse>(`${API_PATH}/ums/versions`);
    },

    // Logout
    logout() {
        return request(`${API_PATH}/ums/admin/logout`);
    },

    // Email Setup
    getMailHooks() {
        return request<undefined, MailHookResponse>(`${API_PATH}/ums/admin/mailhook`);
    },

    createMailHook(workspaceId: string, data: MailHookRequest) {
        return request<MailHookRequest, MailHookResponse>(
            `${API_PATH}/ums/admin/mailhook/${workspaceId}`,
            methods.POST,
            data
        );
    },

    getMailhookById(workspaceId: string, id: string) {
        return request<undefined, MailHookResponse>(`${API_PATH}/ums/admin/mailhook/${workspaceId}/${id}`);
    },

    deleteMailhookById(workspaceId: string, id: string) {
        return request<undefined, undefined | MailHookErrorResponse>(
            `${API_PATH}/ums/admin/mailhook/${workspaceId}/${id}`,
            methods.DELETE
        );
    }
};
