import React, { Suspense } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { MainSpinner } from '@ninox/ninox-components/lib/Spinner';
import { light } from '@ninox/ninox-components/theme';
import { StyleManager } from '@ninox/ninox-components/utils/StyleManager';

import { PrivateCloud } from './Cloud.private';
import { PublicCloud } from './Cloud.public';

const basename = process.env.BASE_NAME || '/';
const routes = process.env.CLOUD === 'public' ? PublicCloud : PrivateCloud(process.env.CLOUD === 'onpremise');
const router = createBrowserRouter(routes, { basename });

const App = () => {
    return (
        <StyleManager>
            <ThemeProvider theme={light}>
                <Suspense fallback={<MainSpinner />}>
                    <RouterProvider router={router} />
                </Suspense>
            </ThemeProvider>
        </StyleManager>
    );
};

export default App;
