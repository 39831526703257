import React from 'react';

import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { Container } from './LinkButton.styles';

type Properties = Omit<React.ComponentProps<typeof Container>, 'children' | 'ref'> & NavLinkProps;

export function LinkButton({ children, ...properties }: Properties) {
    return (
        <Container forwardedAs={NavLink} {...properties}>
            {children}
        </Container>
    );
}
