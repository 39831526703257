import styled from 'styled-components';

import { Icon } from '@ninox/ninox-components/lib/Icon';
import { BorderWidth, Color, Size } from '@ninox/ninox-components/theme';

export const IconWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    height: ${Size.xl};
    width: ${Size.xl};

    border-radius: 50%;
    border-color: ${Color.neutral['700']};
    border-width: ${BorderWidth.small};
    border-style: solid;

    background-color: ${({ theme }) => theme.grid.primary};
`;

export const StyledIcon = styled(Icon).attrs(({ theme }) => ({
    color: theme.icon.secondary,
    size: Size.m
}))``;
