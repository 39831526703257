import React, { Suspense } from 'react';

import { Await, useLoaderData } from 'react-router-dom';

import { MainSpinner } from '@ninox/ninox-components/lib/Spinner';
import { LocaleFormatProvider } from '@ninox/ninox-components/locale';
import { LocaleProvider } from '@ninox/ninox-locales/react';

import { SignIn } from '../shared/SignIn';
import { ProtectedOutlet } from './ProtectedOutlet';
import { SessionContext } from './session.context';
import type { LoaderData } from './session.types';
import { useSession } from './useSession';
import { NotificationsProvider } from '@ninox/ninox-components/lib/Notifications';
import { Size } from '@ninox/ninox-components/theme';

export const SessionLayout = () => {
    const { session } = useLoaderData() as LoaderData;
    const store = useSession(session);

    return (
        <Suspense fallback={<MainSpinner />}>
            <Await errorElement={process.env.MODE === 'production' && <SignIn />} resolve={session}>
                <SessionContext.Provider value={store}>
                    <LocaleProvider language={store?.user?.language}>
                        <LocaleFormatProvider>
                            <NotificationsProvider portal="notifications" style={{ top: Size.l }}>
                                <ProtectedOutlet />
                            </NotificationsProvider>
                        </LocaleFormatProvider>
                    </LocaleProvider>
                </SessionContext.Provider>
            </Await>
        </Suspense>
    );
};
