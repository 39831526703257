import React, { useContext } from 'react';

import type { ComboboxTypes } from '@ninox/ninox-components/lib/Combobox';
import { Combobox as Component } from '@ninox/ninox-components/lib/Combobox';

import { useLabels } from './useLabels';
import { LocaleContext } from '@ninox/ninox-locales/react';

export function Combobox<I extends ComboboxTypes.Item<any>, T extends I['value']>(
    properties: React.ComponentProps<typeof Component<I, T>>
) {
    const locale = useContext(LocaleContext);
    const labels = useLabels();

    return <Component labels={labels} placeholder={locale.select} {...properties} />;
}
