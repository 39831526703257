import { styled } from 'styled-components';

export const Page = styled.div`
    position: fixed;
    inset: 0;

    display: flex;
    flex-direction: column;

    color: ${({ theme }) => theme.text.primary};
    background-color: ${({ theme }) => theme.surface.primary};

    contain: layout;
`;
