import { styled } from 'styled-components';

import { BorderRadius, BorderWidth, FontSize, FontWeight, Spacing } from '@ninox/ninox-components/theme';

export const Info = styled.div`
    color: ${({ theme }) => theme.text.secondary};

    font-size: ${FontSize.small};
    font-weight: ${FontWeight.semibold};
`;

export const VersionTag = styled.span`
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;

    width: fit-content;
    line-height: 1;
    padding: ${Spacing.xxx_small} ${Spacing.xx_small};
    margin: 0 ${Spacing.x_small};

    font-size: ${FontSize.x_small};
    font-weight: ${FontWeight.medium};

    ${({ theme }) => theme.tag.secondary}

    border-style: solid;
    border-width: ${BorderWidth.tiny};
    border-radius: ${BorderRadius.medium};
`;
