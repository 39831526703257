import React, { useCallback, useContext, useMemo } from 'react';

import { Outlet, useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { LocaleContext } from '@ninox/ninox-locales/react';

import { LinkButton } from '../../../../components/LinkButton';
import { CompanyRole } from '../../../../models/ManageClouds/CompanyInfo';
import { SessionContext } from '../../../../session/session.context';
import type { NavRoute } from '../../../../shared/Nav';
import { TabNav } from '../../../../shared/TabNav';
import type { LoaderData } from './ManageCloud.loader';
import { Content } from './ManageCloud.styles';

export default Layout;
export function Layout() {
    const locale = useContext(LocaleContext);
    const data = useLoaderData() as LoaderData;
    const navigate = useNavigate();
    const { user } = useContext(SessionContext);
    const params = useParams();
    const onOrderPage = useMemo(
        () => params['*'] === 'upgrade-subscription' || params['*'] === 'renew-subscription',
        [params]
    );
    const onBack = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            navigate('..', { unstable_viewTransition: true });
        },
        [navigate]
    );

    const isAdmin = useMemo(
        () => user.companyRole === CompanyRole.admin || user.companyRole === CompanyRole.superAdmin,
        [user]
    );

    const isProvisioned = !!data.order.license?.maxUsers;

    const subRoutes = useMemo(
        () =>
            [
                {
                    end: true,
                    value: '',
                    label: locale.subscriptions,
                    trackKey: 'PrivateCloudsSubscriptions'
                },
                isAdmin &&
                    isProvisioned && {
                        value: 'settings',
                        label: locale.settings,
                        trackKey: 'PrivateCloudsSettings'
                    },
                isAdmin &&
                    isProvisioned && {
                        value: 'custom-domain',
                        label: locale.customDomains,
                        trackKey: 'PrivateCloudsCustomDomain'
                    }
            ].filter(Boolean) as NavRoute[],
        []
    );

    return (
        <>
            <LinkButton emphasis="tertiary" icon="chevron_left" narrow to=".." onClick={onBack}>
                {locale.back}
            </LinkButton>

            <Content>
                {onOrderPage ? (
                    <Outlet />
                ) : (
                    <TabNav routes={subRoutes} showTitles={false}>
                        <Outlet />
                    </TabNav>
                )}
            </Content>
        </>
    );
}
