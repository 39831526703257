import React from 'react';

import { SvgIcon, type SvgIconProperties } from '@ninox/ninox-components/lib/Icon/shared';
import { Color, Size } from '@ninox/ninox-components/theme';

export const Lock = ({ color, ...properties }: SvgIconProperties) => {
    return (
        <SvgIcon intrinsicSize={48} stroke="none" {...properties}>
            <g transform="translate(6 0)">
                <path
                    d="M30.5919 22.3591C30.0254 22.3591 29.5663 21.9 29.5663 21.3335V11.4081C29.5663 6.50163 25.2947 2.50838 20.043 2.50838H15.9586C10.707 2.50838 6.43535 6.5 6.43535 11.4081V21.3335C6.43535 21.9 5.97628 22.3591 5.40977 22.3591C4.84326 22.3591 4.38419 21.9 4.38419 21.3335V11.4081C4.38419 5.37024 9.57721 0.457214 15.9586 0.457214H20.043C26.4244 0.457214 31.6174 5.37024 31.6174 11.4081V21.3335C31.6174 21.9 31.1584 22.3591 30.5919 22.3591Z"
                    fill={color}
                />
                <path
                    d="M28.2281 47.5428H7.77186C3.65163 47.5428 0.29814 44.1909 0.29814 40.0707L0.293256 27.9884C0.293256 26.9091 0.291628 21.5158 0.296511 21.3954C0.32093 20.8044 0.804419 20.3242 1.39698 20.3047C1.51744 20.3014 7.32093 20.3014 7.98186 20.3014H27.9807C28.0458 20.3014 34.4419 20.3014 34.5786 20.3047C35.1858 20.3209 35.6791 20.8061 35.7019 21.4068C35.7067 21.537 35.7051 27.3437 35.7051 28.0063L35.7002 40.0707C35.7002 44.1909 32.3484 47.5428 28.2281 47.5428ZM2.34442 22.3542C2.34279 23.453 2.34279 25.4782 2.34442 27.9884L2.3493 40.0707C2.35093 43.0595 4.78302 45.4916 7.77186 45.4916H28.2265C31.2153 45.4916 33.6491 43.0595 33.6491 40.0707L33.6539 28.0063C33.6539 25.4847 33.6539 23.4514 33.6539 22.3542C32.5616 22.3526 30.5202 22.3526 27.9807 22.3526H7.98186C5.46674 22.3526 3.44326 22.3526 2.34442 22.3542Z"
                    fill="currentColor"
                />
                <path
                    d="M22.4491 39.2974H13.5493C13.0398 39.2974 12.6263 38.884 12.6263 38.3744V29.4747C12.6263 28.9651 13.0398 28.5516 13.5493 28.5516H22.4491C22.9586 28.5516 23.3721 28.9651 23.3721 29.4747V38.3744C23.3721 38.884 22.9586 39.2974 22.4491 39.2974ZM14.4723 37.4514H21.526V30.3977H14.4723V37.4514Z"
                    fill={color}
                />
            </g>
        </SvgIcon>
    );
};

Lock.defaultProperties = {
    size: Size.xxl,
    color: Color.accent['700']
};
