import React, { useContext, useMemo } from 'react';

import { Outlet } from 'react-router-dom';

import { LocaleContext } from '@ninox/ninox-locales/react';

import { CompanyRole } from '../../../models/ManageClouds/CompanyInfo';
import { SessionContext } from '../../../session/session.context';
import type { NavRoute } from '../../../shared/Nav';
import { TabNav } from '../../../shared/TabNav';
import { EnableManageClouds } from './EnableManageClouds/EnableManageClouds';
import { OrderContextProvider } from './OrderCloud/OrderCloud.provider';

export const Layout = () => {
    const { user } = useContext(SessionContext);
    const locale = useContext(LocaleContext);

    const routes = useMemo(
        () =>
            [
                {
                    value: '',
                    label: locale.privateClouds,
                    trackKey: 'PrivateClouds',
                    end: true,
                    description: locale.privateCloudsSummary
                },
                user.companyId &&
                    user.companyRole === CompanyRole.superAdmin && {
                        value: 'company-info',
                        label: locale.billingInfo,
                        trackKey: 'CompanyInfo',
                        description: locale.billingInfoSummary
                    },
                user.companyId &&
                    user.companyRole === CompanyRole.superAdmin && {
                        value: 'company-members',
                        label: locale.companyAccountAccess,
                        trackKey: 'CompanyMembers',
                        description: locale.companyAccountAccessSummary
                    }
            ].filter(Boolean) as NavRoute[],
        [locale, user.companyRole]
    );

    const enableRoute = useMemo(
        () => [
            {
                value: '',
                label: locale.enableManagePrivateClouds,
                description: locale.enableManagePrivateCloudsSummary
            }
        ],
        [locale]
    );

    if (!user.companyId) {
        return (
            <TabNav routes={enableRoute}>
                <EnableManageClouds />
            </TabNav>
        );
    }

    return (
        <OrderContextProvider>
            <TabNav routes={routes}>
                <Outlet />
            </TabNav>
        </OrderContextProvider>
    );
};
