import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { DeleteAccount } from './DeleteAccount';
import { Layout } from './Security.layout';

export const Security = {
    element: <Layout />,
    children: [
        {
            path: 'cookie-settings',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "ChangePassword" */ './CookieSettings')
        },
        {
            path: 'delete-account/*',
            children: [DeleteAccount]
        },
        {
            index: true,
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "ChangePassword" */ './ChangePassword')
        }
    ]
} satisfies RouteObject;
