import React, { useContext, useLayoutEffect, useMemo, useState } from 'react';

import { Outlet, useFetcher, useRouteLoaderData } from 'react-router-dom';

import { LocaleContext } from '@ninox/ninox-locales/react';

import type { UserPlanInfo } from '../../../models/UserPlanInfo';
import { Tier } from '../../../models/UserPlanInfo';
import type { LoaderData } from '../../../session/session.types';
import { TabNav } from '../../../shared/TabNav';

export const Layout = () => {
    const { session } = useRouteLoaderData('root') as LoaderData;
    const locale = useContext(LocaleContext);
    const planInfo = useFetcher();
    const [isTrial, setIsTrial] = useState(false);

    useLayoutEffect(() => {
        const userId = session.id;

        planInfo.load(`planInfo/${userId}`);
    }, [session.id]);

    useLayoutEffect(() => {
        if (!planInfo.data) return;

        setIsTrial((planInfo.data as UserPlanInfo).tier === Tier.trial);
    }, [planInfo.data]);

    const tabRoutes = useMemo(
        () => [
            {
                value: '',
                label: locale.planTiers,
                end: true,
                trackKey: 'PlanTiers',
                description: isTrial ? locale.chooseSubscription : locale.planGetNowCTA
            },
            {
                value: 'usage',
                label: locale.usageOverview,
                trackKey: 'UsageOverview',
                description: locale.usageOverviewSummary
            },
            {
                value: 'collaborators',
                label: locale.collaborators,
                trackKey: 'SubscriptionsCollaborators',
                description: locale.collaboratorsSummary
            }
        ],
        [locale, isTrial]
    );

    return (
        <TabNav routes={tabRoutes}>
            <Outlet />
        </TabNav>
    );
};
