import React, { useCallback, useContext } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useMediaQuery } from '@ninox/ninox-components/hooks/useMediaQuery';
import { Logo } from '@ninox/ninox-components/lib/Icon';
import { MediaQuery, Size } from '@ninox/ninox-components/theme';
import { startViewTransition } from '@ninox/ninox-components/utils/startViewTransition';
import { LocaleContext } from '@ninox/ninox-locales/react';

import { AppButton } from './AppButton';
import { MenuButton } from './MenuButton';
import { Bold, Container, IconContainer, MainButton } from './TopNav.styles';

type Properties = React.ComponentProps<typeof Container> & {
    isPublic?: boolean;
    isSidebarOpened?: boolean;
    mediaQuery?: string;
    setIsOpened?: React.Dispatch<React.SetStateAction<boolean>>;
    withoutMenus?: boolean;
};

export const TopNav = ({
    isPublic = false,
    isSidebarOpened,
    mediaQuery = MediaQuery.S,
    setIsOpened,
    withoutMenus,
    ...properties
}: Properties) => {
    const locale = useContext(LocaleContext);
    const navigate = useNavigate();
    const isMatching = useMediaQuery(mediaQuery);
    const theme = useTheme();

    const toHome = useCallback((e: React.MouseEvent) => {
        e?.preventDefault();
        navigate(process.env.BASE_NAME || '/', { unstable_viewTransition: true });
    }, []);

    const toggle = useCallback(() => {
        startViewTransition(() => setIsOpened?.((prev) => !prev));
    }, [setIsOpened]);

    return (
        <Container {...properties}>
            {!withoutMenus && !isMatching && setIsOpened && (
                <MenuButton isOpened={!!isSidebarOpened} onClick={toggle} />
            )}

            <MainButton as={NavLink} data-testid="ninox-settings" to="/" onClick={toHome}>
                <IconContainer>
                    <Logo color={theme.icon.accent} size={Size.s} />
                </IconContainer>

                <Bold>{locale.ninoxSettings}</Bold>
                <span>{isPublic ? '' : location.hostname}</span>
            </MainButton>

            {!withoutMenus && (
                <AppButton data-testid="admin-profile" isPublic={isPublic} style={{ marginLeft: 'auto' } as const} />
            )}
        </Container>
    );
};
