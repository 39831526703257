import { styled } from 'styled-components';

import { FontSize, FontWeight, Spacing } from '@ninox/ninox-components/theme';

export const Container = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    box-sizing: border-box;
    width: 50vw;
    padding: ${Spacing.xx_large};
    gap: ${Spacing.xx_large};
    margin: auto;

    background-color: ${({ theme }) => theme.surface.primary};
    text-decoration: none;
`;

export const Header = styled.header`
    font-size: ${FontSize.xxxx_large};
    font-weight: ${FontWeight.semibold};

    color: ${({ theme }) => theme.text.primary};
`;

export const Text = styled.div`
    font-size: ${FontSize.medium};
    font-weight: ${FontWeight.semibold};
    color: ${({ theme }) => theme.text.secondary};
`;
