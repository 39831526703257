export const domainOptions = [
    {
        label: '',
        options: [
            {
                label: 'ninoxdb.de',
                value: 'ninoxdb.de'
            },
            {
                label: 'ninoxdb.com',
                value: 'ninoxdb.com'
            }
        ]
    }
];

export enum ProviderDisplayNames {
    Hetzner = 'Hetzner',
    AWS = 'Amazon Web Services (AWS)'
}

export const ProviderRegionNames = {
    'fsn1': 'Falkenstein, Germany',
    'nbg1': 'Nuremberg, Germany',
    'hel1': 'Helsinki, Finland',
    'ash': 'Ashburn, Virginia, USA',
    'hil': 'Hillsboro, Oregon, USA',
    'sin': 'Singapore',
    'ap-northeast-1': 'Asia Pacific (Tokyo)',
    'ap-northeast-2': 'Asia Pacific (Seoul)',
    'ap-south-1': 'Asia Pacific (Mumbai)',
    'ap-southeast-1': 'Asia Pacific (Singapore)',
    'ap-southeast-2': 'Asia Pacific (Sydney)',
    'ca-central-1': 'Canada (Central)',
    'eu-central-1': 'Europe (Frankfurt)',
    'eu-central-2': 'Europe (Zurich)',
    'eu-north-1': 'Europe (Stockholm)',
    'eu-west-1': 'Europe (Ireland)',
    'eu-west-2': 'Europe (London)',
    'eu-west-3': 'Europe (Paris)',
    'sa-east-1': 'South America (São Paulo)',
    'us-east-1': 'US East (N. Virginia)',
    'us-east-2': 'US East (Ohio)',
    'us-west-1': 'US West (N. California)',
    'us-west-2': 'US West (Oregon)'
};
