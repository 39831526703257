import { styled } from 'styled-components';

export const Main = styled.div`
    position: relative;
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    box-sizing: border-box;
    height: -webkit-fill-available;
    overflow: hidden;
`;

export const Relative = styled.div`
    display: flex;
    min-width: 0;
    width: 100%;
    position: relative;
`;
