import React from 'react';

import type { RouteObject } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { Layout } from './Integrations.layout';

export const Integrations = {
    element: <Layout />,
    children: [
        {
            path: 'tools',
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "ConnectedApps" */ '../../../shared/ConnectedApps')
        },
        {
            index: true,
            errorElement: <ErrorElement />,
            lazy: () => import(/* webpackChunkName: "ApiKeys" */ './ApiKeys')
        }
    ]
} satisfies RouteObject;
