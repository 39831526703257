import { styled } from 'styled-components';

import { Spacing } from '@ninox/ninox-components/theme';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: start;

    width: 100%;
    max-width: 60rem;

    gap: ${Spacing.large};
`;

export const CardWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    width: 100%;
    gap: inherit;

    && > * {
        width: auto;
        height: auto;
    }
`;
