import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useIds } from '@ninox/ninox-components/hooks/useId';
import { useMoveFocus } from '@ninox/ninox-components/hooks/useMoveFocus';

import type { NavRoute } from '../Nav';
import { NavContext } from '../Nav';
import { Container, Finished, Pending, Progress, Small, TabPanel, Track } from './ProgressNav.styles';
import { ProgressTab } from './ProgressTab';

const ids = ['selected', 'panel'] as const;

type Properties = React.ComponentProps<typeof Container> & {
    /**
     * if true, then forward routing is prevented
     */
    backwardsOnly: boolean;
    routes: readonly NavRoute[];
};

export function ProgressNav({ children, backwardsOnly, routes, ...properties }: Properties) {
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { selected, panel } = useIds(ids);
    const moveFocus = useMoveFocus(ref, selected.id, '[role="tab"]:not([aria-disabled="true"])');
    const [currentRoute, setCurrentRoute] = useState(routes[0]);

    const onKeyDown = useCallback((e: React.KeyboardEvent) => {
        switch (e.code) {
            case 'ArrowLeft':
                moveFocus(false);
                break;
            case 'ArrowRight':
                moveFocus(true);
                break;
            case 'Home':
                moveFocus('first');
                break;
            case 'End':
                moveFocus('last');
                break;
        }
    }, []);

    useEffect(() => {
        // update currentRoute if routes are changing
        if (!ref.current) return;

        const link = ref.current.querySelector<HTMLAnchorElement>(`[aria-current]`);

        if (!link) return;

        const path = link.dataset.path || '';

        setCurrentRoute(routes.find(({ value }) => value === path) || routes[0]);
    }, [location.pathname]);

    const progress = useMemo(() => {
        if (!currentRoute) return 0;

        return routes.findIndex(({ value }) => value === currentRoute.value);
    }, [currentRoute]);

    return (
        <>
            <Container ref={ref} role="tablist" tabIndex={-1} {...properties} onKeyDown={onKeyDown}>
                <Progress role="progress" steps={routes.length}>
                    <Track />
                    <Finished progress={progress} steps={routes.length} />
                    <Pending progress={progress} steps={routes.length} />
                </Progress>

                {routes.map((route, index) => {
                    const isCurrent = index === progress;
                    const isChecked = index < progress;
                    const isDisabled = backwardsOnly ? index > progress : !!route.disabled;

                    return (
                        <ProgressTab
                            key={route.value}
                            controls={panel.id}
                            id={isCurrent ? selected.id : undefined}
                            index={index}
                            isChecked={isChecked}
                            isCurrent={isCurrent}
                            isDisabled={isDisabled}
                            to={route.value}
                            onSelect={() => {
                                setCurrentRoute(route);
                                navigate(route.value, { unstable_viewTransition: true });
                            }}
                        >
                            {route.label}
                            {route.description && <Small>{route.description}</Small>}
                        </ProgressTab>
                    );
                })}
            </Container>

            <TabPanel aria-labelledby={selected.id} id={panel.id} role="tabpanel" tabIndex={-1}>
                <NavContext.Provider value={currentRoute}>{children}</NavContext.Provider>
            </TabPanel>
        </>
    );
}
