import { styled } from 'styled-components';

import { SimpleButton } from '@ninox/ninox-components/lib/Button';
import { BorderWidth, FontWeight } from '@ninox/ninox-components/theme';

export const Container = styled(SimpleButton)<{ size: string }>`
    height: ${({ size }) => size};
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    border-width: 0;

    outline-offset: 0;
    outline-width: ${BorderWidth.tiny};
    outline-color: ${({ theme }) => theme.grid.secondary};
    outline-style: solid;

    font-size: calc(${({ size }) => size} / 2);
    font-weight: ${FontWeight.bold};
`;

export const Image = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    pointer-events: none;
    contain: layout paint size;
`;

export const Placeholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    pointer-events: none;

    background-color: ${({ theme }) => theme.surface.primary};
    color: ${({ theme }) => theme.text.label};
`;
