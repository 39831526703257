import { styled } from 'styled-components';

import { BorderRadius, BorderWidth, MediaQuery, Spacing } from '@ninox/ninox-components/theme';

export const Section = styled.section`
    width: 100%;
    margin-bottom: ${Spacing.small};

    &:first-child {
        margin-top: -${Spacing.small};
    }

    @media ${MediaQuery.S} {
        width: auto;
        min-width: 25rem;
        white-space: nowrap;
    }
`;

export const ButtonContainer = styled.div`
    margin-top: ${Spacing.x_small};
`;

export const DMS = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: ${Spacing.xxx_small} ${Spacing.x_small};
    margin: ${Spacing.x_small} 0;
    padding: ${Spacing.medium};

    color: ${({ theme }) => theme.text.primary};
    background-color: ${({ theme }) => theme.surface.primary};

    border-radius: ${BorderRadius.medium};
    border: ${BorderWidth.tiny} solid ${({ theme }) => theme.grid.primary};
`;
