import React from 'react';

import { SvgIcon, type SvgIconProperties } from '@ninox/ninox-components/lib/Icon/shared';
import { Color, Size } from '@ninox/ninox-components/theme';

export const EmailAccount = ({ color, ...properties }: SvgIconProperties) => {
    return (
        <SvgIcon intrinsicSize={55} stroke="none" {...properties}>
            <path
                d="M40.7153 23.2481C40.1749 23.2286 39.724 23.6519 39.7044 24.1907L39.4472 31.5716C39.3251 35.0749 37.5295 38.3568 34.6433 40.3542C32.7793 41.6451 30.5898 42.3272 28.3123 42.3272C26.0365 42.3272 23.8486 41.6451 21.983 40.3574C21.4849 40.0123 21.0112 39.6233 20.5716 39.2C18.483 37.183 17.2767 34.4742 17.1758 31.5733L16.8942 23.4467C16.8747 22.9079 16.4237 22.483 15.8849 22.5042C15.346 22.5221 14.9244 22.9747 14.9423 23.5135L15.224 31.64C15.3428 35.0505 16.7607 38.233 19.2156 40.6049C19.6047 40.9809 20.0181 41.3342 20.4495 41.6581V45.0084C20.4495 49.3435 23.9772 52.8712 28.3123 52.8712C32.6491 52.8712 36.1767 49.3435 36.1767 45.0084V41.6549C39.3202 39.2879 41.2623 35.5844 41.4007 31.64L41.6579 24.2591C41.6774 23.7186 41.2542 23.2677 40.7153 23.2481ZM34.2233 45.0084C34.2233 48.2674 31.5714 50.9177 28.3123 50.9177C25.0533 50.9177 22.403 48.2674 22.403 45.0084V42.8726C24.2263 43.7972 26.2384 44.2807 28.3123 44.2807C30.3879 44.2807 32.4016 43.7956 34.2233 42.8709V45.0084Z"
                fill="currentColor"
            />
            <path
                d="M12.323 53.0893H4.95186C3.56651 53.0893 2.44 51.9628 2.44 50.5774V43.2046C2.44 42.6658 2.00209 42.2279 1.46326 42.2279C0.924418 42.2279 0.486511 42.6658 0.486511 43.2046V50.5758C0.486511 53.0388 2.49046 55.0412 4.95186 55.0412H12.323C12.8619 55.0412 13.2998 54.6033 13.2998 54.0644C13.2998 53.5256 12.8619 53.0893 12.323 53.0893Z"
                fill="currentColor"
            />
            <path
                d="M12.323 0.957214H4.95186C2.48884 0.957214 0.486511 2.96117 0.486511 5.42256V12.7937C0.486511 13.3326 0.924418 13.7705 1.46326 13.7705C2.00209 13.7705 2.44 13.3326 2.44 12.7937V5.42256C2.44 4.03721 3.56651 2.9107 4.95186 2.9107H12.323C12.8619 2.9107 13.2998 2.4728 13.2998 1.93396C13.2998 1.39512 12.8619 0.957214 12.323 0.957214Z"
                fill="currentColor"
            />
            <path
                d="M50.0481 0.991394H42.677C42.1381 0.991394 41.7002 1.4293 41.7002 1.96814C41.7002 2.50698 42.1381 2.94488 42.677 2.94488H50.0481C51.4335 2.94488 52.56 4.07139 52.56 5.45674V12.8279C52.56 13.3667 52.9979 13.8047 53.5367 13.8047C54.0756 13.8047 54.5135 13.3667 54.5135 12.8279V5.45837C54.5151 2.99535 52.5112 0.991394 50.0481 0.991394Z"
                fill="currentColor"
            />
            <path
                d="M53.5384 42.2279C52.9995 42.2279 52.5616 42.6658 52.5616 43.2046V50.5758C52.5616 51.9612 51.4351 53.0877 50.0498 53.0877H42.6786C42.1398 53.0877 41.7019 53.5256 41.7019 54.0644C41.7019 54.6033 42.1398 55.0412 42.6786 55.0412H50.0498C52.5128 55.0412 54.5151 53.0372 54.5151 50.5758V43.2046C54.5151 42.6658 54.0772 42.2279 53.5384 42.2279Z"
                fill="currentColor"
            />
            <path
                d="M40.6812 25.2049C40.6258 25.2049 40.5705 25.2 40.5167 25.1902C40.1521 25.1284 39.8526 24.863 39.7467 24.5081C39.5107 23.7202 39.33 22.9274 39.1574 22.1591C38.4607 19.0774 37.8014 16.1537 33.5526 12.8149C31.9963 15.9795 29.7465 18.6835 26.9905 20.6891C23.79 23.017 19.9758 24.3372 15.963 24.5049C15.5658 24.5228 15.2158 24.2998 15.0498 23.9677C14.1788 22.9421 10.586 18.2602 11.5416 11.9C12.0772 8.33651 13.5065 5.85721 14.3107 4.69488C14.8772 3.87605 15.7302 3.33721 16.7119 3.17767C17.6902 3.01814 18.667 3.25581 19.463 3.84512L22.683 6.14372C23.2284 6.53279 23.9495 6.60279 24.5665 6.32605C26.9726 5.24674 31.7114 3.60419 36.4063 4.74209C36.4144 4.74372 36.4242 4.74698 36.4323 4.7486C36.574 4.78767 39.9209 5.72535 41.806 8.61326C43.9419 11.8853 44.7591 20.3114 41.4723 24.8044C41.2835 25.0584 40.9888 25.2049 40.6812 25.2049ZM17.2995 5.08233C17.2084 5.08233 17.1156 5.09046 17.0244 5.10512C16.5702 5.18 16.1763 5.42907 15.9142 5.80674C14.7095 7.54535 13.8191 9.87163 13.4707 12.1898C12.6926 17.3763 15.4974 21.3972 16.3928 22.5253C23.3602 22.0402 29.4112 17.6351 32.2681 10.9281C32.3837 10.6563 32.6165 10.4512 32.9014 10.3714C33.1863 10.2916 33.4923 10.3437 33.7333 10.5163C39.2926 14.477 40.2547 18.1756 40.9986 21.4558C42.3384 17.526 41.6644 11.9781 40.1667 9.68116C38.7423 7.49814 36.1133 6.6907 35.9244 6.63535C31.8009 5.64233 27.5391 7.13023 25.3626 8.10698C24.1156 8.66698 22.6521 8.52209 21.5451 7.73256L18.3186 5.42907C18.3121 5.42581 18.3072 5.42093 18.3023 5.41605C18.0077 5.19791 17.6593 5.08233 17.2995 5.08233Z"
                fill={color}
            />
        </SvgIcon>
    );
};

EmailAccount.defaultProperties = {
    size: Size.xxl,
    color: Color.accent['700']
};
