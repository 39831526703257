import { styled } from 'styled-components';

import { BorderRadius, BorderWidth, FontFamily, FontSize, FontWeight, Spacing } from '@ninox/ninox-components/theme';

export const Code = styled.pre`
    padding: ${Spacing.small};
    margin: 0;

    color: ${({ theme }) => theme.text.secondary};
    background-color: ${({ theme }) => theme.surface.secondary};

    border-radius: ${BorderRadius.medium};
    border-color: ${({ theme }) => theme.grid.secondary};
    border-style: solid;
    border-width: ${BorderWidth.tiny};

    font-size: ${FontSize.x_small};
    font-family: ${FontFamily.mono};
    font-weight: ${FontWeight.semibold};

    white-space: pre-wrap;
    text-align: start;

    &:empty {
        display: none;
    }
`;
