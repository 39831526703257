import { styled } from 'styled-components';

import { FontSize, FontWeight, Size, Spacing } from '@ninox/ninox-components/theme';

export const Container = styled.div`
    display: grid;
    grid-auto-columns: 1fr auto 1fr;
    align-items: center;
    justify-content: center;
    justify-items: center;

    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 60rem;
    margin: ${Spacing.large} auto;
    isolation: isolate;
    outline: none;
`;

export const Progress = styled.div<{ steps: number }>`
    position: relative;
    grid-column: 2 / ${({ steps }) => steps * 3};
    grid-row: 1;

    display: flex;
    width: calc(100% - ${Size.sm});
`;

export const Track = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    background-color: ${({ theme }) => theme.icon.tertiary};

    box-sizing: content-box;
    height: ${Spacing.tiny};

    transform: translateY(-50%);

    transition: all 400ms ease-in-out;
`;

export const Finished = styled(Track)<{ progress: number; steps: number }>`
    left: 0;
    right: ${({ steps, progress }) => Math.round(100 * (1 - progress / (steps - 1)))}%;

    background-color: ${({ theme }) => theme.icon.secondary};
`;

export const Pending = styled(Track)<{ progress: number; steps: number }>`
    left: ${({ steps, progress }) => Math.round(100 * Math.max(0, (progress - 1) / (steps - 1)))}%;
    right: ${({ steps, progress }) => Math.round(100 * (1 - progress / (steps - 1)))}%;

    background: linear-gradient(to right, ${({ theme }) => theme.icon.secondary}, ${({ theme }) => theme.icon.accent});
`;

export const Small = styled.div`
    font-size: ${FontSize.xx_small};
    font-weight: ${FontWeight.semibold};
    text-decoration: none;
    text-wrap: pretty;
`;

export const TabPanel = styled.div`
    position: sticky;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 auto;
    box-sizing: border-box;
    min-width: 100%;
`;
