import { createContext } from 'react';

import type { OrderContextType } from './OrderCloud.types';

export const initialContext: OrderContextType = {
    state: {
        city: '',
        companyId: '',
        companyName: '',
        country: '',
        countryCode: '',
        currency: 'Eur',
        diskSize: 100,
        dmsStorageUnitPerGB: 0,
        email: '',
        features: {
            SSO: false,
            DMS: false
        },
        firstName: '',
        hostingProvider: 'Hetzner',
        hostingRegion: '',
        invoicingEmail: '',
        lastName: '',
        maxUsers: 10,
        ninoxDomain: 'ninoxdb.de',
        numberOfHrLicenses: 0,
        numberOfReadonlyLicenses: 0,
        numberOfRestrictedLicenses: 0,
        orderedBy: '',
        region: '',
        startDate: '',
        street: '',
        subDomain: '',
        vat: '',
        vatIsValid: null,
        zip: ''
    },
    actions: {
        resetOrder: () => console.warn('reset order', 'not implemented'),
        updateOrder: () => console.warn('updateOrder', 'not implemented')
    }
};

export const OrderContext = createContext(initialContext);
