import React from 'react';

import { SvgIcon, type SvgIconProperties } from '@ninox/ninox-components/lib/Icon/shared';
import { Color, Size } from '@ninox/ninox-components/theme';

export const Erase = ({ color, ...properties }: SvgIconProperties) => {
    return (
        <SvgIcon intrinsicSize={55} stroke="none" {...properties}>
            <path
                d="M44.275 44.6041H28.8624C28.9315 44.5074 28.9956 44.4069 29.0558 44.3027L42.8359 20.4347C43.3181 19.5983 43.4475 18.6238 43.1976 17.692C42.9476 16.7589 42.3499 15.9803 41.5135 15.4968L29.1751 8.37383C28.3388 7.8916 27.3643 7.76225 26.4324 8.01216C25.5006 8.26207 24.7208 8.85983 24.2373 9.69621L10.456 33.5642C9.45886 35.291 10.0529 37.5062 11.7796 38.5021L24.118 45.625C24.6291 45.9202 25.1917 46.0809 25.7656 46.106C25.7907 46.1085 25.8171 46.1098 25.8435 46.1098H25.8874C25.8987 46.1098 25.91 46.111 25.9213 46.111C25.9326 46.111 25.9439 46.1098 25.9552 46.1098H44.2776C44.6932 46.1098 45.031 45.772 45.031 45.3563C45.031 44.9406 44.6907 44.6041 44.275 44.6041ZM25.5421 10.451C25.8234 9.9637 26.278 9.61458 26.8217 9.4689C27.3655 9.32323 27.9331 9.39858 28.4216 9.67988L40.76 16.8029C41.2473 17.0842 41.5964 17.5388 41.7421 18.0825C41.8877 18.6263 41.8124 19.1939 41.5311 19.6824L30.8655 38.1555L14.8764 28.924L25.5421 10.451ZM12.5331 37.1985C11.5259 36.6171 11.1806 35.3249 11.762 34.319L14.1242 30.2288L30.1132 39.4603L27.751 43.5504C27.4697 44.0377 27.0151 44.3868 26.4714 44.5325C25.9276 44.6782 25.36 44.6028 24.8715 44.3215L12.5331 37.1985Z"
                fill="currentColor"
            />
            <path
                d="M31.0199 9.43854C30.6595 9.23007 30.1986 9.3544 29.9901 9.71481L17.5174 31.3186C17.3089 31.679 17.4333 32.1399 17.7937 32.3484C17.913 32.4174 18.0423 32.4488 18.1692 32.4488C18.4291 32.4488 18.6828 32.3132 18.8222 32.0721L31.2949 10.4683C31.5034 10.1079 31.3803 9.647 31.0199 9.43854Z"
                fill={color}
            />
            <path
                d="M39.6674 14.4317C39.3069 14.2232 38.8461 14.3476 38.6376 14.708L26.1649 36.3105C25.9564 36.6709 26.0807 37.1318 26.4411 37.3403C26.5604 37.4093 26.6898 37.4407 26.8166 37.4407C27.0766 37.4407 27.3302 37.3051 27.4696 37.064L39.9424 15.4615C40.1509 15.101 40.0278 14.6402 39.6674 14.4317Z"
                fill={color}
            />
        </SvgIcon>
    );
};

Erase.defaultProperties = {
    size: Size.xxl,
    color: Color.accent['700']
};
