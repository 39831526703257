import { styled } from 'styled-components';

import { FontSize, Spacing } from '@ninox/ninox-components/theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${Spacing.x_small};

    height: 100%;
    width: 100%;
`;

export const Title = styled.div`
    font-size: ${FontSize.large};
    font-weight: bold;
`;

export const Content = styled.div`
    font-size: ${FontSize.large};
`;
