import { Form as Base } from 'react-router-dom';
import { styled } from 'styled-components';

import { Spacing } from '@ninox/ninox-components/theme';

export const Form = styled(Base)`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: ${Spacing.medium};
    width: 100%;
    max-width: 48rem;
`;
