import React, { useContext, useEffect } from 'react';

import { useTheme } from 'styled-components';

import { Logo } from '@ninox/ninox-components/lib/Icon';
import { Spinner } from '@ninox/ninox-components/lib/Spinner';
import { LocaleContext } from '@ninox/ninox-locales/react';
import { BorderWidth, Size } from '@ninox/ninox-components/theme';

import { signInUrl } from '../../utils/signInUrl';
import { Page } from '../styles/Page';
import { Container, Header } from './SignIn.styles';

type Properties = {
    noRedirect?: boolean;
};

export const SignIn = ({ noRedirect }: Properties) => {
    const locale = useContext(LocaleContext);
    const url = signInUrl(noRedirect);
    const theme = useTheme();

    useEffect(() => {
        const timeout = setTimeout(
            async () => {
                window.location.replace(url.href);
            },
            process.env.MODE !== 'production' ? 6000 : 2000
        );

        return () => {
            clearTimeout(timeout);
        };
    }, [url.href]);

    return (
        <Page>
            <Container as="a" href={url.href}>
                <Logo color={theme.icon.accent} size={Size.xxl} />
                <Header>{locale.signIn}</Header>
                <Spinner
                    borderWidth={BorderWidth.medium}
                    color={theme.icon.secondary}
                    size={Size.m}
                    style={{ opacity: 1 } as const}
                />
            </Container>
        </Page>
    );
};
