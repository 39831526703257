import React, { useContext, useLayoutEffect } from 'react';

import { Navigate, useFetcher } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Button } from '@ninox/ninox-components/lib/Button';
import { LocaleContext } from '@ninox/ninox-locales/react';
import { toFormData } from '@ninox/ninox-components/utils/formData';

import { SessionContext } from '../../../../session/session.context';
import { InfoCard } from '../../../../shared/InfoCard';
import type { ActionResult } from '../Overview/Overview.action';
import { CardWrapper, Container } from './EnableManageClouds.styles';

export const EnableManageClouds = () => {
    const locale = useContext(LocaleContext);
    const fetcher = useFetcher();
    const { user, setUser } = useContext(SessionContext);
    const actionResult = fetcher.data as ActionResult;
    const isLoading = fetcher.state !== 'idle';
    const theme = useTheme();

    const onSubmit = () => {
        fetcher.submit(
            toFormData({
                ownerId: user.id,
                updatedBy: user.id
            }),
            {
                method: 'POST'
            }
        );
    };

    useLayoutEffect(() => {
        if (actionResult?._id) {
            const companyId = actionResult._id;
            const companyRole = actionResult.members.find((u) => u.user === user.id)?.role || user.companyRole;

            setUser((u) => ({ ...u, companyId, companyRole }));
        }
    }, [actionResult?._id]);

    if (!user?.enableCompanyAccountSelfService) {
        return <Navigate to=".." />;
    }

    return (
        <Container>
            <Button disabled={isLoading} emphasis="primary" onClick={onSubmit}>
                {locale.dateFixEnable}
            </Button>

            <CardWrapper>
                <InfoCard
                    content={locale.configurationAndSharingBody}
                    icon={{ color: theme.icon.accent, icon: 'cloud', filling: 1 }}
                    title={locale.configurationAndSharingHeader}
                />
                <InfoCard
                    content={locale.maintenanceAndUpdatesBody}
                    icon={{ color: theme.icon.accent, icon: 'settings', filling: 1 }}
                    title={locale.maintenanceAndUpdatesHeader}
                />
                <InfoCard
                    content={locale.payAsYouGoBody}
                    icon={{ color: theme.icon.accent, icon: 'payments', filling: 1 }}
                    title={locale.payAsYouGoHeader}
                />
            </CardWrapper>
        </Container>
    );
};
