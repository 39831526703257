import { styled, css } from 'styled-components';

import { SimpleButton } from '@ninox/ninox-components/lib/Button';
import { Size, Spacing } from '@ninox/ninox-components/theme';

export const Container = styled(SimpleButton)<{ isOpened: boolean }>`
    position: relative;
    height: ${Size.s};
    padding: ${Spacing.xx_small} ${Spacing.tiny};
    aspect-ratio: 1 / 1;
    border-width: 0;
    box-sizing: content-box;

    &:focus-visible {
        color: ${({ theme }) => theme.icon.accent};
    }

    .line-1,
    .line-2,
    .line-3 {
        position: absolute;
        top: 50%;
        left: ${Spacing.tiny};

        width: ${Size.s};
        height: ${Spacing.tiny};
        background-color: currentColor;

        border-radius: calc(${Spacing.tiny} / 2);

        transition-property: all;
        transition-duration: 400ms;
        transition-timing-function: ease;
    }

    .line-1 {
        transform: translateY(calc(-3 * ${Spacing.tiny} - 50%));
    }

    .line-2 {
        transform: translateY(-50%);
    }

    .line-3 {
        transform: translateY(calc(3 * ${Spacing.tiny} - 50%));
    }

    ${({ isOpened }) =>
        isOpened &&
        css`
            .line-1 {
                transform: rotate(45deg);
            }

            .line-3 {
                transform: rotate(-45deg);
            }

            .line-2 {
                opacity: 0;
            }
        `}
`;
