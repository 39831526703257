import { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { LocaleContext } from '@ninox/ninox-locales/react';

import { init as initTracking } from '../tracking/MixPanel';
import type { SessionUser } from './session.types';

export const useSession = (session: SessionUser) => {
    const [user, setUser] = useState<SessionUser>(session);

    useEffect(() => {
        initTracking();
    }, []);

    useEffect(() => {
        setUser(session);
    }, [session]);

    if (process.env.MODE !== 'production') {
        // Allow using an upper locale context to set languages in storybook
        const locale = useContext(LocaleContext);
        const language = locale.LANG || '';

        useLayoutEffect(() => {
            setUser({ ...session, language });
        }, [language]);
    }

    const store = useMemo(
        () => ({
            user,
            setUser
        }),
        [user]
    );

    return store;
};
