import React, { useContext, useMemo } from 'react';

import { Outlet } from 'react-router-dom';

import { LocaleContext } from '@ninox/ninox-locales/react';

import { TabNav } from '../../../shared/TabNav';

export const Layout = () => {
    const locale = useContext(LocaleContext);

    const tabRoutes = useMemo(
        () => [
            {
                value: '',
                end: true,
                label: locale.changePassword,
                description: locale.changePasswordSummary,
                trackKey: 'ChangePassword'
            },
            {
                value: 'cookie-settings',
                label: locale.cookieConsent,
                description: locale.cookieConsentSummary,
                trackKey: 'CookieSettings'
            },
            {
                value: 'delete-account',
                label: locale.deleteAccount,
                description: (
                    <>
                        <div>{locale.deleteAccountData}</div>
                        <div>{locale.deleteAccountDuration}</div>
                    </>
                ),
                trackKey: 'DeleteAccount'
            }
        ],
        [locale]
    );

    return (
        <TabNav routes={tabRoutes}>
            <Outlet />
        </TabNav>
    );
};
