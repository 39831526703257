import React from 'react';

import type { IconType } from '@ninox/ninox-components/lib/Icon';

import { Container } from './InfoCard.styles';
import { LargeIconLayout } from '../LargeIconLayout';

type Properties = {
    content: string;
    icon: IconType;
    title: string;
};

export const InfoCard = ({ content, icon, title }: Properties) => {
    return (
        <Container>
            <LargeIconLayout content={content} icon={icon} title={title} />
        </Container>
    );
};
