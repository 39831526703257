import { styled } from 'styled-components';

import { FontSize, FontWeight, Spacing } from '@ninox/ninox-components/theme';

export const Container = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;

    max-width: 28rem;

    padding: ${Spacing.large};
    margin: auto;
    gap: ${Spacing.medium};

    font-size: ${FontSize.medium};
    text-align: center;
    line-height: 2;
    color: ${({ theme }) => theme.text.secondary};

    &:after {
        content: '';
        padding-top: 25%;
    }
`;

export const Header = styled.header`
    margin-top: ${Spacing.small};
    line-height: 1.5;

    color: ${({ theme }) => theme.text.header};

    font-size: ${FontSize.xxx_large};
    font-weight: ${FontWeight.medium};
`;
