import { type ComponentProps, useContext, useMemo } from 'react';

import type { Combobox as Component } from '@ninox/ninox-components/lib/Combobox';
import { LocaleContext } from '@ninox/ninox-locales/react';

export function useLabels() {
    const locale = useContext(LocaleContext);

    return useMemo<ComponentProps<typeof Component>['labels']>(() => {
        return {
            empty: locale.empty,
            selectAll: locale.selectAll
        };
    }, [locale]);
}
