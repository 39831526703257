import React from 'react';
import { IconWrapper, StyledIcon } from './LargeIcon.styles';
import type { IconOptions, IconType } from '@ninox/ninox-components/lib';

type Props = {
    icon: IconType;
    options?: IconOptions;
};

export const LargeIcon = ({ icon, options }: Props) => {
    if (typeof icon === 'function') {
        const IconComponent = icon;

        return (
            <IconWrapper>
                <IconComponent {...options} />
            </IconWrapper>
        );
    }

    const iconProps = typeof icon === 'string' ? { icon } : icon;

    return (
        <IconWrapper>
            <StyledIcon icon={{ ...iconProps, ...options }} />
        </IconWrapper>
    );
};
