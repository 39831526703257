import React from 'react';

import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { ErrorElement } from '../../../shared/ErrorElement';
import { Page } from '../../../shared/styles/Page';
import { TopNav } from '../../../shared/TopNav';
import { Layout } from './Account.layout';

export const Account = {
    element: <Layout />,
    errorElement: (
        <Page>
            <TopNav />
            <ErrorElement centered />
        </Page>
    ),
    children: [
        {
            path: 'verify-email',
            lazy: () => import(/* webpackChunkName: "VerifyEmail" */ './VerifyEmail')
        },
        {
            path: 'reset-password',
            lazy: () => import(/* webpackChunkName: "ResetPassword" */ './ResetPassword')
        },
        {
            path: 'accept-invitation',
            lazy: () => import(/* webpackChunkName: "AcceptInvitation" */ './AcceptInvitation')
        },
        {
            path: '*',
            element: <Navigate replace to="../.." />
        }
    ]
} satisfies RouteObject;
