import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '@ninox/ninox-components/lib/Button';
import { LocaleContext } from '@ninox/ninox-locales/react';

import { ContactSupport as Icon } from '../../../shared/Icons';
import { Main } from '../../../shared/styles/Main';
import { Page } from '../../../shared/styles/Page';
import { TopNav } from '../../../shared/TopNav';
import { track } from '../../../tracking/MixPanel';
import { Container, Header } from '../Account/shared.styles';

export const ContactSales = () => {
    const navigate = useNavigate();
    const locale = useContext(LocaleContext);

    const goToSubscriptionPlans = () => {
        track('PlanTiers', 'BackToSubscriptions');

        navigate('../subscriptions', { unstable_viewTransition: true, replace: true });
    };

    return (
        <Page>
            <TopNav isPublic withoutMenus />
            <Main>
                <Container>
                    <Icon />
                    <Header>{locale.contactSalesConfirmationHeader}</Header>
                    <div>{locale.contactSalesConfirmationBody}</div>
                    <Button emphasis="accent" onClick={goToSubscriptionPlans}>
                        {locale.backToSubscriptions}
                    </Button>
                </Container>
            </Main>
        </Page>
    );
};
