import React from 'react';

import { Container } from './MenuButton.styles';

type Properties = React.ComponentProps<typeof Container> & {
    onClick?: () => void;
    isOpened: boolean;
};

export const MenuButton = ({ isOpened, ...properties }: Properties) => {
    return (
        <Container isOpened={isOpened} {...properties}>
            <div className="line-1" id="a" />
            <div className="line-2" id="b" />
            <div className="line-3" id="c" />
            <div className="clear" />
        </Container>
    );
};
