import React, { useContext, useMemo } from 'react';

import { useIds } from '@ninox/ninox-components/hooks/useId';
import { useSortItems } from '@ninox/ninox-components/hooks/useSortItems';
import { Button } from '@ninox/ninox-components/lib/Button';
import { Table, TD, TH, THead, TR } from '@ninox/ninox-components/lib/Table';
import { TableHead } from '@ninox/ninox-components/lib/Table/TableHead';
import { LocaleFormatContext } from '@ninox/ninox-components/locale';
import { LocaleContext } from '@ninox/ninox-locales/react';

import { useSortingLabels } from '../../hooks/useSortingLabels';
import type { DeployStatus } from '../../models/DeployStatus';
import type { Version } from '../../models/Version';
import { Info, VersionTag } from './Versions.styles';

type Properties = {
    currentVersion: string;
    deploymentStatus: DeployStatus | undefined;
    versions: Version[];
    setDeployVersion: (version: string) => void;
};

const ids = ['column', 'row'] as const;

export const Versions = ({ versions, setDeployVersion, currentVersion, deploymentStatus }: Properties) => {
    const locale = useContext(LocaleContext);
    const { date } = useContext(LocaleFormatContext);
    const { column, row } = useIds(ids);
    const { items: sorted, ...sorting } = useSortItems(versions, 'releasedAt', 'desc');
    const labels = useSortingLabels();
    const isDeploying = deploymentStatus && deploymentStatus.progress < 100;
    const deployingVersion = deploymentStatus?.source;
    const lastVersion = useMemo(
        () => versions.sort((a, b) => b.releasedAt.localeCompare(a.releasedAt))[0]?.label,
        [versions]
    );

    return (
        <>
            <Table>
                <THead>
                    <TR>
                        <TableHead column="label" id={`${column.id}-1`} labels={labels} {...sorting}>
                            {locale.serverVersion}
                        </TableHead>
                        <TableHead column="releasedAt" id={`${column.id}-2`} labels={labels} {...sorting}>
                            {/* TBD */}
                            {locale.releaseDate}
                        </TableHead>
                        <TH aria-label={locale.deploy} id={`${column.id}-3`} />
                    </TR>
                </THead>
                {sorted.map((version, index: number) => {
                    const { label, releasedAt } = version;
                    const isCurrent = currentVersion === label;
                    const isDeployingThis = isDeploying && deployingVersion === label;
                    const isLast = label === lastVersion;

                    return (
                        <TR key={index} aria-labelledby={`${row.id}-${index}`} aria-rowindex={index + 1}>
                            <TD aria-labelledby={`${column.id}-1`}>
                                <span id={`${row.id}-${index}`}>{version.label}</span>
                                {isLast && <VersionTag>{locale.latestVersion.replaceAll(':', '')}</VersionTag>}
                                {isCurrent && <VersionTag>{locale.current}</VersionTag>}
                            </TD>

                            <TD aria-labelledby={`${column.id}-2`}>{date(releasedAt)}</TD>
                            <TD aria-labelledby={`${column.id}-3`}>
                                {!isDeploying ? (
                                    <Button emphasis="secondary" onClick={() => setDeployVersion(label)}>
                                        {locale.deploy}
                                    </Button>
                                ) : (
                                    <Button
                                        aria-valuenow={deploymentStatus?.progress}
                                        disabled
                                        emphasis="quaternary"
                                        role={'progressbar'}
                                    >
                                        {(isDeployingThis &&
                                            locale.deployProgress$.replace('$', `${deploymentStatus.progress}`)) || (
                                            <>&nbsp;</>
                                        )}
                                    </Button>
                                )}
                            </TD>
                        </TR>
                    );
                })}
            </Table>

            {currentVersion && currentVersion >= lastVersion && <Info>{locale.latestVersionSuccess}</Info>}
        </>
    );
};
