export async function request<Params extends Record<string, any> | undefined = any, Response = any>(
    url: string,
    method = 'GET',
    data = {} as Params,
    options: RequestInit = {}
): Promise<Response | undefined> {
    const fetchOptions: RequestInit = {
        method,
        credentials: 'include',
        ...options
    };

    if (data instanceof FormData) {
        fetchOptions.body = data;
    } else if (['PUT', 'POST', 'DELETE', 'PATCH'].includes(method)) {
        fetchOptions.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...fetchOptions.headers
        };

        fetchOptions.body = JSON.stringify(data);
    }

    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
        throw response;
    }

    if (response.headers.get('content-length') === '0') {
        return;
    }

    const cloned = response.clone();

    try {
        return await response.json();
    } catch (error) {
        const message = await cloned.text();

        return {
            error,
            message: message || `${error}`
        } as Response;
    }
}
